import type { Dispatch, Commit } from 'vuex';
import { NAMESPACE, MutationTypes, ActionTypes, IOrganismState } from '@/models/store/organism.interface';

export const mutationsWrapper = {
	setShouldShowSearchInput({ commit }: { commit: Commit }, isShow: IOrganismState['shouldShowSearchInput']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOULD_SHOW_SEARCH_INPUT}`, isShow, { root: true });
	},
	setShouldCustomizeHeader({ commit }: { commit: Commit }, shouldCustomize: IOrganismState['shouldCustomizeHeader']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOULD_CUSTOMIZE_HEADER}`, shouldCustomize, { root: true });
	},
	setShowTutorialModal({ commit }: { commit: Commit }, embedLink: IOrganismState['showTutorialModal']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_TUTORIAL_MODAL}`, embedLink, { root: true });
	},
	setShowPromotionHeader({ commit }: { commit: Commit }, isShow: IOrganismState['shouldShowSearchInput']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_PROMOTION_HEADER}`, isShow, { root: true });
	},
	setCategoryFooterCount({ commit }: { commit: Commit }, payload: IOrganismState['categoryCount']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_CATEGORY_COUNT}`, payload, { root: true });
	},
	setShouldHighlightHeader({ commit }: { commit: Commit }, payload: IOrganismState['shouldHighlightHeader']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOULD_HIGHLIGHT_HEADER}`, payload, { root: true });
	},
	toggleSearchPageFilter({ commit }: { commit: Commit }, payload: IOrganismState['isSearchPageFilterExpanded']) {
		commit(`${NAMESPACE}/${MutationTypes.TOGGLE_SEARCH_PAGE_FILTER}`, payload, { root: true });
	},
};

export const actionsWrapper = {
	getCategoryFooterCount({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_CATEGORY_FOOTER_COUNT}`, null, { root: true });
	},
	getFooterSeo({ dispatch }: { dispatch: Dispatch }, payload: { type: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_FOOTER_SEO}`, payload, { root: true });
	},
};
