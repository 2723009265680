import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
import { IAssetsLibraryState, SEARCH_TAB } from '@/models/store/pixcapassets.interface';

export const getDefaultState = function (): IAssetsLibraryState {
	return {
		activeExplorerTab: null,
		activeSearchItemType: null,
		activeSearchItemResourceType: LIBRARY_RESOURCE_TYPE.ELEMENTS,

		libraryFilter: {},
		packFilter: {
			category: null,
			search: null,
			isPro: null,
			resourceTypes: null,
		},
		templateFilter: {
			category: null,
			search: null,
			isPro: null,
			topic: null,
		},

		topicFilter: null,

		librarySearch: null,
		showOnlyAnimatedFiles: null,

		showOnlyProFiles: null,
		showOnlyProPacks: null,
		showPackOrElement: SEARCH_TAB.ITEMS,

		isLoadingSearchSuggestions: false,
		searchSuggestions: [],

		isFetchingLibraryFiles: {},
		libraryFilesState: {},

		isFetchingPacks: false,
		libraryPacksState: getDefaultLibraryState(LIBRARY_FILES_PAGE_SIZE),

		isFetchingTemplates: false,
		libraryTemplateState: getDefaultLibraryState(LIBRARY_FILES_PAGE_SIZE),

		isFetchingTemplateDetails: false,
		templateDetailsState: null,
		selectedLibraryTemplateSlug: null,

		isFetchingPackDetails: false,
		packDetailsState: null,

		isFetchingRelatedModels: false,
		relatedModels: [],

		isFetchingRelatedTemplates: false,
		relatedTemplates: [],

		isFetchingRelatedPacks: false,
		relatedPacks: [],

		filterDesignSize: null,

		selectedLibraryItemSlug: null,

		isFetchingItemDetails: false,
		libraryItemDetails: null,

		usecaseTopics: {
			page: null,
			totalPages: null,
			totalItems: null,
			content: [],
		},
		isFetchingUsecaseTopics: false,

		searchPagePackCount: 0,
		searchPageItemCount: 0,
		shouldHideSearchPageSuggestedPacks: false,
	};
};

export function getDefaultLibraryState(pageSize: number) {
	return {
		listOfItems: [],
		currentPage: 0,
		totalItems: 0,
		totalPages: null,
		pageSize,
	};
}

export const LIBRARY_FILES_PAGE_SIZE = 50;

export default (): IAssetsLibraryState => getDefaultState();
