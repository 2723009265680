import { getDefaultLibraryState, LIBRARY_FILES_PAGE_SIZE } from './state';
import { IAssetsLibraryState, MutationTypes } from '@/models/store/pixcapassets.interface';

export default {
	[MutationTypes.SET_ACTIVE_EXPLORER_TAB](state: IAssetsLibraryState, activeExplorerTab) {
		state.activeExplorerTab = activeExplorerTab;
	},
	[MutationTypes.SET_ACTIVE_SEARCH_ITEM_TYPE](state: IAssetsLibraryState, activeSearchItemType) {
		state.activeSearchItemType = activeSearchItemType;
	},
	[MutationTypes.SET_ACTIVE_SEARCH_ITEM_RESOURCE_TYPE](state: IAssetsLibraryState, activeSearchItemResourceType) {
		state.activeSearchItemResourceType = activeSearchItemResourceType;
	},
	[MutationTypes.UPDATE_LIBRARY_FILTER](state: IAssetsLibraryState, payload) {
		const { libraryFilter, stateKey } = payload;
		const currentFilter = state.libraryFilter[stateKey];
		state.libraryFilter = {
			...state.libraryFilter,
			[stateKey]: {
				...currentFilter,
				...libraryFilter,
			},
		};
	},
	[MutationTypes.UPDATE_PACK_FILTER](state: IAssetsLibraryState, packFilter) {
		state.packFilter = packFilter;
	},
	[MutationTypes.UPDATE_TEMPLATE_FILTER](state: IAssetsLibraryState, libraryFilter) {
		if (libraryFilter) {
			state.templateFilter = {
				...state.templateFilter,
				...libraryFilter,
			};
		} else {
			state.templateFilter = {};
		}
	},
	[MutationTypes.UPDATE_SHOW_ONLY_ANIMATED_FILES](state: IAssetsLibraryState, showAnimated) {
		if (showAnimated != state.showOnlyAnimatedFiles) {
			state.showOnlyAnimatedFiles = showAnimated;
		}
	},
	[MutationTypes.UPDATE_FILTER_DESIGN_SIZE](state: IAssetsLibraryState, size: string) {
		state.filterDesignSize = size;
	},
	[MutationTypes.UPDATE_SHOW_ONLY_PRO_FILES](state: IAssetsLibraryState, isPro) {
		if (isPro != state.showOnlyProFiles) {
			state.showOnlyProFiles = isPro;
		}
	},
	[MutationTypes.UPDATE_SHOW_ONLY_PRO_PACKS](state: IAssetsLibraryState, isPro) {
		if (isPro != state.showOnlyProPacks) {
			state.libraryPacksState.totalPages = null;
			state.showOnlyProPacks = isPro;
		}
	},
	[MutationTypes.UPDATE_SHOW_PACK_OR_ELEMENT](state: IAssetsLibraryState, showPackOrElement) {
		state.showPackOrElement = showPackOrElement;
	},
	[MutationTypes.UPDATE_LIBRARY_SEARCH](state: IAssetsLibraryState, search) {
		if (search != state.librarySearch) state.librarySearch = search;
	},
	[MutationTypes.SET_IS_FETCHING_SEARCH_SUGGESTIONS](state: IAssetsLibraryState, isLoadingSearchSuggestions: boolean) {
		state.isLoadingSearchSuggestions = isLoadingSearchSuggestions;
	},
	[MutationTypes.UPDATE_SEARCH_SUGGESTIONS](state: IAssetsLibraryState, searchSuggestions: string[]) {
		state.searchSuggestions = searchSuggestions;
	},

	[MutationTypes.SET_IS_FETCHING_LIBRARY_FILES](state: IAssetsLibraryState, payload) {
		const { isLoading, stateKey } = payload;
		state.isFetchingLibraryFiles = {
			...state.isFetchingLibraryFiles,
			[stateKey]: isLoading,
		};
	},
	[MutationTypes.UPDATE_LIBRARY_FILES_STATE](state: IAssetsLibraryState, payload) {
		const { currentPage, totalPages, listOfItems, totalItems, pageSize, stateKey } = payload;
		const itemTypeState = state.libraryFilesState[stateKey];
		state.libraryFilesState = {
			...state.libraryFilesState,
			[stateKey]: {
				pageSize,
				currentPage,
				totalPages,
				totalItems,
				listOfItems: [...itemTypeState?.listOfItems, ...listOfItems],
			},
		};
	},
	[MutationTypes.RESET_LIBRARY_FILES_STATE](state: IAssetsLibraryState, stateKey) {
		state.libraryFilesState = {
			...state.libraryFilesState,
			[stateKey]: getDefaultLibraryState(LIBRARY_FILES_PAGE_SIZE),
		};
	},
	[MutationTypes.SET_IS_FETCHING_LIBRARY_PACKS](state: IAssetsLibraryState, isLoading) {
		state.isFetchingPacks = isLoading;
	},
	[MutationTypes.UPDATE_LIBRARY_PACKS_STATE](state: IAssetsLibraryState, payload) {
		const { currentPage, totalPages, listOfItems, pageSize, totalItems } = payload;

		state.libraryPacksState = {
			pageSize,
			currentPage,
			totalPages,
			totalItems,
			listOfItems: [...state.libraryPacksState.listOfItems, ...listOfItems],
		};
	},
	[MutationTypes.RESET_LIBRARY_PACKS_STATE](state: IAssetsLibraryState) {
		state.libraryPacksState = getDefaultLibraryState(LIBRARY_FILES_PAGE_SIZE);
	},

	// Templates
	[MutationTypes.SET_IS_FETCHING_LIBRARY_TEMPLATES](state: IAssetsLibraryState, isLoading) {
		state.isFetchingTemplates = isLoading;
	},
	[MutationTypes.UPDATE_LIBRARY_TEMPLATES_STATE](state: IAssetsLibraryState, payload) {
		const { currentPage, totalPages, listOfItems, pageSize, totalItems } = payload;

		state.libraryTemplateState = {
			pageSize,
			currentPage,
			totalPages,
			totalItems,
			listOfItems: [...state.libraryTemplateState.listOfItems, ...listOfItems],
		};
	},
	[MutationTypes.RESET_LIBRARY_TEMPLATES_STATE](state: IAssetsLibraryState) {
		state.libraryTemplateState = getDefaultLibraryState(LIBRARY_FILES_PAGE_SIZE);
	},
	//

	[MutationTypes.SET_IS_FETCHING_TEMPLATE_DETAILS](state: IAssetsLibraryState, isLoading) {
		state.isFetchingTemplateDetails = isLoading;
	},
	[MutationTypes.UPDATE_TEMPLATE_DETAILS_STATE](state: IAssetsLibraryState, templateDetails) {
		state.templateDetailsState = templateDetails;
	},
	[MutationTypes.SET_SELECTED_LIBRARY_TEMPLATE_SLUG](state: IAssetsLibraryState, selectedLibraryTemplateSlug) {
		state.selectedLibraryTemplateSlug = selectedLibraryTemplateSlug;
	},

	[MutationTypes.SET_IS_FETCHING_PACK_DETAILS](state: IAssetsLibraryState, isLoading) {
		state.isFetchingPackDetails = isLoading;
	},
	[MutationTypes.UPDATE_PACK_DETAILS_STATE](state: IAssetsLibraryState, packDetails) {
		state.packDetailsState = packDetails;
	},

	[MutationTypes.SET_IS_FETCHING_RELATED_MODELS](state: IAssetsLibraryState, isFetchingRelatedModels) {
		state.isFetchingRelatedModels = isFetchingRelatedModels;
	},
	[MutationTypes.UPDATE_RELATED_MODELS](state: IAssetsLibraryState, relatedModels) {
		state.relatedModels = relatedModels;
	},

	[MutationTypes.SET_IS_FETCHING_RELATED_TEMPLATES](state: IAssetsLibraryState, isFetchingRelatedTemplates) {
		state.isFetchingRelatedTemplates = isFetchingRelatedTemplates;
	},
	[MutationTypes.UPDATE_RELATED_TEMPLATES](state: IAssetsLibraryState, relatedTemplates) {
		state.relatedTemplates = relatedTemplates;
	},

	[MutationTypes.SET_IS_FETCHING_RELATED_PACKS](state: IAssetsLibraryState, isFetchingRelatedPacks) {
		state.isFetchingRelatedPacks = isFetchingRelatedPacks;
	},
	[MutationTypes.UPDATE_RELATED_PACKS](state: IAssetsLibraryState, relatedPacks) {
		state.relatedPacks = relatedPacks;
	},
	[MutationTypes.SET_IS_FETCHING_ITEM_DETAILS](state: IAssetsLibraryState, isLoading) {
		state.isFetchingItemDetails = isLoading;
	},
	[MutationTypes.UPDATE_LIBRARY_ITEM_DETAILS](state: IAssetsLibraryState, libraryItemDetails) {
		state.libraryItemDetails = libraryItemDetails;
	},
	[MutationTypes.SET_SELECTED_LIBRARY_ITEM_SLUG](state: IAssetsLibraryState, selectedLibraryItemSlug) {
		state.selectedLibraryItemSlug = selectedLibraryItemSlug;
	},

	// TOPICS
	[MutationTypes.SET_IS_FETCHING_USECASE_TOPICS](state: IAssetsLibraryState, isFetching: boolean) {
		state.isFetchingUsecaseTopics = isFetching;
	},
	[MutationTypes.UPDATE_USECASE_TOPICS](state: IAssetsLibraryState, topics) {
		state.usecaseTopics = topics;
	},
	[MutationTypes.UPDATE_USECASE_TOPICS_FILTER](state: IAssetsLibraryState, topics) {
		state.topicFilter = topics;
	},

	[MutationTypes.UPDATE_SEARCH_PAGE_PACK_COUNT](state: IAssetsLibraryState, count) {
		state.searchPagePackCount += count;
	},

	[MutationTypes.RESET_SEARCH_PAGE_PACK_COUNT](state: IAssetsLibraryState) {
		state.searchPagePackCount = 0;
	},

	[MutationTypes.UPDATE_SEARCH_PAGE_ITEM_COUNT](state: IAssetsLibraryState, count) {
		state.searchPageItemCount += count;
	},

	[MutationTypes.RESET_SEARCH_PAGE_ITEM_COUNT](state: IAssetsLibraryState) {
		state.searchPageItemCount = 0;
	},

	[MutationTypes.SET_SHOULD_HIDE_SEARCH_PAGE_SUGGESTED_PACKS](state: IAssetsLibraryState, isHide: boolean) {
		state.shouldHideSearchPageSuggestedPacks = isHide;
	},
};
