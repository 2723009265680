import { IOrganismState } from '@/models/store/organism.interface';

export default (): IOrganismState => ({
	shouldShowSearchInput: false,
	shouldCustomizeHeader: false,
	showTutorialModal: null,
	showPromotionHeader: false,

	shouldHighlightHeader: false,
	isSearchPageFilterExpanded: true,

	categoryCount: null,
});
